<template>
  <!-- Button -->
  <v-btn
    :color="color"
    @click="e => $emit('click', e)"
    :icon="!!icon && !Value"
    :absolute="absolute"
    :append="append"
    :block="block"
    :bottom="bottom"
    :dark="dark"
    :depressed="depressed"
    :disabled="disabled"
    :exact="exact"
    :fab="fab"
    :fixed="fixed"
    :large="large"
    :left="left"
    :light="light"
    :link="link"
    :loading="loading"
    :nuxt="nuxt"
    :outlined="outlined"
    :plain="plain"
    :replace="replace"
    :retain-focus-on-click="retainFocusOnClick"
    :right="right"
    :rounded="rounded"
    :shaped="shaped"
    :small="small"
    :text="text"
    :tile="tile"
    :top="top"
    :x-large="xLarge"
    :x-small="xSmall"
    :to="to"
    :width="Size"
    :height="Size"
  >
    <template v-if="!Size">
      <!-- Icon -->
      <v-icon v-if="!!icon" :left="!!Value">{{ Icon }}</v-icon>
      <!-- Text -->
      <template v-if="!!Value">{{ Value }}</template>
      <!-- Button End -->
    </template>

    <!-- Big Button -->
    <div v-else>
      <v-icon :size="Size * 0.6">{{ Icon }}</v-icon
      ><br />
      <span v-if="!!Value" :style="Style">{{ Value }}</span>
    </div>
    <!-- Big Button End -->
  </v-btn>
</template>

<script>
export default {
  name: "Button",
  props: {
    value: String,
    color: String,
    icon: String,
    absolute: Boolean,
    append: Boolean,
    block: Boolean,
    bottom: Boolean,
    dark: Boolean,
    depressed: Boolean,
    disabled: Boolean,
    exact: Boolean,
    fab: Boolean,
    fixed: Boolean,
    large: Boolean,
    left: Boolean,
    light: Boolean,
    link: Boolean,
    loading: Boolean,
    nuxt: Boolean,
    outlined: Boolean,
    plain: Boolean,
    replace: Boolean,
    retainFocusOnClick: Boolean,
    right: Boolean,
    rounded: Boolean,
    shaped: Boolean,
    small: Boolean,
    text: Boolean,
    tile: Boolean,
    top: Boolean,
    xLarge: Boolean,
    xSmall: Boolean,
    to: String,
    sMiddle: Boolean,
    middle: Boolean,
    big: Boolean,
    xBig: Boolean,
    size: [String, Number],
    fontSize: [String, Number]
  },
  computed: {
    Value() {
      return this.$slots.default ? this.$slots.default[0].text : this.value;
    },
    Size() {
      if (this.size) return this.size;
      else if (this.xBig) return 250;
      else if (this.big) return 200;
      else if (this.middle) return 150;
      else if (this.sMiddle) return 60;
      else return null;
    },
    Icon() {
      return this.icon.match(/^mdi-.*$/g) ? this.icon : "mdi-" + this.icon;
    },
    Style() {
      return `font-size: ${
        this.fontSize ? this.fontSize : this.Size / (this.Value.length * 1.2)
      }px;`;
    }
  }
};
</script>

<style></style>
